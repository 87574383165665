import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { RESPONSIVE_STYLES } from "../../../../AppConst"
import { TitleAndCaption } from "../../common/TitleAndCaption"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 760px;
  margin: auto;
`
const Contents = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 48px 40px;
  margin-top: 48px;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    gap: 24px;
    margin-top: 24px;
  }
  @media (max-width: ${RESPONSIVE_STYLES.SP_MAX_WIDTH}px) {
    grid-template-columns: repeat(2, 1fr);
  }
`
const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  max-width: 160px;
  @media (max-width: ${RESPONSIVE_STYLES.SP_MAX_WIDTH}px) {
    max-width: unset;
  }
`

const Image = styled(Img)`
  width: 100%;
  background: #FFFFFF;
`
const Name = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
  text-align: center;
  color: #222222;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    font-size: 14px;
    line-height: 28px;
  }
`

type Props = {
  title: string
  caption: string
  imgSkills: Array<any>
  names: Array<string>
}
export const Skill: React.VFC<Props> = ({title, caption, imgSkills, names}) => {

  return (
    <Wrapper>
      <TitleAndCaption title={title} caption={caption} />
      <Contents>
        {
          names.map((name, i) => {
            return (
              <Content key={i}>
                <Image fluid={imgSkills[i]} alt={name} />
                <Name>{name}</Name>
              </Content>
            )
          })
        }
      </Contents>
    </Wrapper>
  )
}
