import React from "react"
import styled from "styled-components"
import { RESPONSIVE_STYLES } from "../../../../AppConst"
import { TitleAndCaption } from "../../common/TitleAndCaption"

import ImgFlow from "../../../../images/service/development_flow.svg"
import ImgFlowTb from "../../../../images/service/development_flow_tb.svg"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const Contents = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
  margin-top: 48px;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    gap: 24px;
    margin-top: 24px;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 48px 96px;
  box-sizing: border-box;
  background: #FFFFFF;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    padding: 32px;
  }
`
const Image = styled.img`
  width: 100%;
  /* @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    display: none;
  } */
`
const ImageTb = styled.img`
  width: 100%;
  display: none;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    display: inherit;
  }
`

type Props = {
  title: string
  caption: string
}
export const Flow: React.VFC<Props> = ({title, caption}) => {

  return (
    <Wrapper>
      <TitleAndCaption title={title} caption={caption} />
      <Contents>
        <Content>
          <Image src={ImgFlow} alt={title} />
        </Content>
      </Contents>
    </Wrapper>
  )
}
