import React from "react"
import Layout from "../../../template/Layout"
import Head from "../../../head";
import {
  Props as BreadcrumbsData,
} from "../../common/Breadcrumbs"
import { ServiceContainer } from "../common/ServiceContainer";
import { SectionTitle } from "../../common/SectionTitle";

import { ServiceContentContainer } from "../common/ServiceContentContainer";
import { MainVisual } from "../common/MainVisual";
import { Message } from "../common/Message";
import { GrayContainer } from "../common/GrayContainer";
import { Strength } from "../common/Strength";
import { CaseStudy } from "./common/CaseStudy";
import { Flow } from "./common/Flow";
import { Skill } from "./common/Skill";
import { RequestJobButtonLink } from "../common/RequestJobButtonLink";
import { BeigeContainer } from "../common/BeigeContainer";
import { TriadLab } from "./common/TriadLab";
import { WhiteContainer } from "../common/WhiteContainer";
import { Members } from "../common/Members";
import { Faq } from "../common/Faq";
import { ogpUrl } from "../../../utlis/ImageUtil"

/**
 * Component
 */
type Props = {
  breadcrumbsData: BreadcrumbsData["values"]
  icon: any
  imgMain: any
  imgSkills: Array<any>
  imgTriadFlow: any
  imgTriadFlowSp: any
  imgTriadLogo: any
  development: any
  members: Array<any>
}

/**
 * Component
 */
export const ServiceDevelopment: React.VFC<Props> = (props) => {

  return (
    <Layout sidebar={false} breadcrumbsData={props.breadcrumbsData}>
      <Head
        title={props.development.ogp.title}
        description={props.development.ogp.description}
        img={ogpUrl(process.env.NODE_ENV, props.imgMain)}
      />
      <ServiceContainer paddingBottom={0}>
        <SectionTitle title={props.development.title.en} subTitle={props.development.title.ja} icon={props.icon} />
        <ServiceContentContainer>
          <MainVisual img={props.imgMain} alt={props.development.title.ja} />
          <Message title={props.development.message.title} description={props.development.message.description} />
          <GrayContainer>
            <Strength
              title={props.development.strength.title.ja}
              caption={props.development.strength.title.en}
              contents={props.development.strength.contents}
            />
            <CaseStudy
              title={props.development.case_study.title.ja}
              caption={props.development.case_study.title.en}
              contents={props.development.case_study.contents}
              remarks={props.development.case_study.remarks}
            />
            <Flow
              title={props.development.flow.title.ja}
              caption={props.development.flow.title.en}
            />
            <Skill
              title={props.development.skill.title.ja}
              caption={props.development.skill.title.en}
              imgSkills={props.imgSkills}
              names={props.development.skill.names}
            />
            <RequestJobButtonLink />
          </GrayContainer>
          {/*
          <BeigeContainer>
            <TriadLab
              title={props.development.triad.title}
              description={props.development.triad.description}
              remarks={props.development.triad.remarks}
              imgFlow={props.imgTriadFlow}
              imgFlowSp={props.imgTriadFlowSp}
              imgLogo={props.imgTriadLogo}
            />
            <RequestJobButtonLink />
          </BeigeContainer>
          */}
          <WhiteContainer>
            <Members
              title={props.development.member.title.ja}
              caption={props.development.member.title.en}
              members={props.members}
            />
          </WhiteContainer>
          <GrayContainer>
            <Faq
              title={props.development.faq.title.ja}
              contents={props.development.faq.contents}
              url={'/question/development'}
            />
          </GrayContainer>
        </ServiceContentContainer>
      </ServiceContainer>
    </Layout>
  )
}
