import React from "react"
import styled from "styled-components"
import { RESPONSIVE_STYLES } from "../../../../AppConst"
import { TitleAndCaption } from "../../common/TitleAndCaption"

import ImgCase1 from "../../../../images/service/development_case_1.svg"
import ImgCase2 from "../../../../images/service/development_case_2.svg"
import ImgCase3 from "../../../../images/service/development_case_3.svg"
import ImgCase4 from "../../../../images/service/development_case_4.svg"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const Contents = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
  margin-top: 48px;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    gap: 24px;
    margin-top: 24px;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 48px 96px;
  box-sizing: border-box;
  background: #FFFFFF;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    padding: 32px;
  }
`
const Point = styled.span`
  margin: 0;
  font-family: 'Righteous';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #61B1C1;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    font-size: 16px;
    line-height: 24px;
  }
`
const Title = styled.h4`
  margin: 16px 0 0;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  text-transform: capitalize;
  color: #222222;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    font-size: 16px;
    line-height: 24px;
  }
`
const Description = styled.p`
  margin: 8px 0 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  color: #222222;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    font-size: 14px;
    line-height: 28px;
  }
`
const Price = styled.p`
  position: relative;
  margin: 8px 0 0;
  padding-left: 60px;
  box-sizing: border-box;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #222222;
  &:before {
    content: '費用';
    position: absolute;
    left: 0;
    display: inline-block;
    padding: 0 8px;
    color: #FFFFFF;
    background: #222222;
    font-weight: 600;
    font-size: 14px;
    line-height: 28px;
  }
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    padding-left: 48px;
    font-size: 14px;
    line-height: 21px;
    &:before {
      font-size: 12px;
      line-height: 24px;
    }
  }
`

const Image = styled.img`
  margin-top: 28px;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    margin-top: 16px;
  }
`
const Remarks = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Remark = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  color: #222222;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    font-size: 14px;
    line-height: 28px;
  }
`

type Props = {
  title: string
  caption: string
  contents: Array<any>
  remarks: Array<any>
}
export const CaseStudy: React.VFC<Props> = ({title, caption, contents, remarks}) => {
  const images = [
    ImgCase1,
    ImgCase2,
    ImgCase3,
    ImgCase4,
  ]

  return (
    <Wrapper>
      <TitleAndCaption title={title} caption={caption} />
      <Contents>
        {
          contents.map((content, i) => {
            return (
              <Content key={i}>
                <Point>POINT 0{i + 1}</Point>
                <Title>{content.title}</Title>
                <Description>{content.description}</Description>
                <Price>{content.price}</Price>
                <Image src={images[i]} alt={`${content.title}｜${content.description}｜${content.price}`} />
              </Content>
            )
          })
        }
        <Remarks>
          {
            remarks.map((remark, i) => {
              return (
                <Remark key={i}>{remark}</Remark>
              )
            })
          }
        </Remarks>
      </Contents>
    </Wrapper>
  )
}
