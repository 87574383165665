import React from "react"
import { graphql } from "gatsby"
import { ServiceDevelopment } from "../../../components/service/development"
import Icon from "../../../images/service/icon_service_development.svg"

export default function ServiceDevelopmentPage({ data }) {
    const {
      imgMain,
      imgSkill1,
      imgSkill2,
      imgSkill3,
      imgSkill4,
      imgSkill5,
      imgSkill6,
      imgSkill7,
      imgSkill8,
      imgTriadFlow,
      imgTriadFlowSp,
      imgTriadLogo,
      site: { siteMetadata: { services, member } }
    } = data;

    const imgSkills = [
      imgSkill1.childImageSharp.fluid,
      imgSkill2.childImageSharp.fluid,
      imgSkill3.childImageSharp.fluid,
      imgSkill4.childImageSharp.fluid,
      imgSkill5.childImageSharp.fluid,
      imgSkill6.childImageSharp.fluid,
      imgSkill7.childImageSharp.fluid,
      imgSkill8.childImageSharp.fluid,
    ]

    const breadcrumbsData: { text: string; path: string }[] = [
      { text: services.top.title.en, path: services.top.path },
      { text: services.development.title.ja, path: services.development.path },
    ]

    return (
      <ServiceDevelopment
        breadcrumbsData={breadcrumbsData}
        icon={Icon}
        imgMain={imgMain.childImageSharp.fluid}
        imgSkills={imgSkills}
        imgTriadFlow={imgTriadFlow.childImageSharp.fluid}
        imgTriadFlowSp={imgTriadFlowSp.childImageSharp.fluid}
        imgTriadLogo={imgTriadLogo.childImageSharp.fluid}
        development={services.development}
        members={member.filter(m => m.development > 0).sort((a, b) => {
          if (a.development < b.development) return -1;
          if (a.development > b.development) return 1;
          return 0;
        })}
      />
    )
}

export const query = graphql`
  query ServiceDevelopmentQuery {
    site {
      siteMetadata {
        services {
          top {
            path
            title {
              ja
              en
            }
          }
          development {
            path
            ogp {
              title
              description
            }
            title {
              ja
              en
            }
            description
            message {
              title
              description
            }
            strength {
              title {
                ja
                en
              }
              contents {
                title
                descriptions
              }
            }
            case_study {
              title {
                ja
                en
              }
              contents {
                title
                description
                price
              }
              remarks
            }
            flow {
              title {
                ja
                en
              }
            }
            skill {
              title {
                ja
                en
              }
              names
            }
            triad {
              title
              description
              remarks
            }
            member {
              title {
                ja
                en
              }
            }
            faq {
              title {
                ja
              }
              contents {
                question
                answer
              }
            }
          }
        }
        member {
          position
          name
          pathname
          alt
          member_index_filename
          color
          development
        }
      }
    }
    imgMain: file(relativePath: { eq: "images/service/development_thumbnail.JPG"}) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imgSkill1: file(relativePath: { eq: "images/service/development_skill_ruby.png"}) {
      childImageSharp {
        fluid(maxWidth: 160, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imgSkill2: file(relativePath: { eq: "images/service/development_skill_python.png"}) {
      childImageSharp {
        fluid(maxWidth: 160, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imgSkill3: file(relativePath: { eq: "images/service/development_skill_net.png"}) {
      childImageSharp {
        fluid(maxWidth: 160, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imgSkill4: file(relativePath: { eq: "images/service/development_skill_php.png"}) {
      childImageSharp {
        fluid(maxWidth: 160, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imgSkill5: file(relativePath: { eq: "images/service/development_skill_javascript.png"}) {
      childImageSharp {
        fluid(maxWidth: 160, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imgSkill6: file(relativePath: { eq: "images/service/development_skill_react.png"}) {
      childImageSharp {
        fluid(maxWidth: 160, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imgSkill7: file(relativePath: { eq: "images/service/development_skill_xamarin.png"}) {
      childImageSharp {
        fluid(maxWidth: 160, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imgSkill8: file(relativePath: { eq: "images/service/development_skill_docker.png"}) {
      childImageSharp {
        fluid(maxWidth: 160, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imgTriadFlow: file(relativePath: { eq: "images/service/development_triad_flow.png"}) {
      childImageSharp {
        fluid(maxWidth: 2400, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imgTriadFlowSp: file(relativePath: { eq: "images/service/development_triad_flow_sp.png"}) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imgTriadLogo: file(relativePath: { eq: "images/service/development_triad_logo.png"}) {
      childImageSharp {
        fluid(maxWidth: 320, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    } 
  }
`
